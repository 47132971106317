import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { SkeletonTableLoadingComponent } from './skeleton-table-loading/skeleton-table-loading.component';
import { UploadFileComponent } from './upload/upload.component';
import { NotificationsDropdownMenuComponent } from './notifications-dropdown-menu/notifications-dropdown-menu.component';
import { MessagesDropdownMenuComponent } from './messages-dropdown-menu/messages-dropdown-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MedicalDocumentsUploadComponent } from './uploader/medical-documents-upload/medical-documents-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FileUploadComponent } from './uploader/file-upload/file-upload.component';
import { CredentialsComponent } from './credentials/credentials.component';
import { DidntReceiveCodeModalComponent } from './sign-up/didnt-receive-code-modal/didnt-receive-code-modal.component';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { SkeletonModule } from 'primeng/skeleton';
import { ClinicDetailComponent } from './clinic-management/clinic-detail/clinic-detail.component';
import { ClinicManageScheduleComponent } from './clinic-management/clinic-manage-schedule/clinic-manage-schedule.component';
import { ClinicManagementComponent } from './clinic-management/clinic-management/clinic-management.component';
import { ClinicBlocksScheduleComponent } from './clinic-management/clinic-blocks-schedule/clinic-blocks-schedule.component';
import { ClinicRegularAvailabilityComponent } from './clinic-management/clinic-regular-availability/clinic-regular-availability.component';
import { ClinicTimeBlockGeneratorComponent } from './clinic-management/clinic-time-block-generator/clinic-time-block-generator.component';
import { AccordionModule } from 'primeng/accordion';
import { ClinicSpecificScheduleComponent } from './clinic-management/clinic-specific-schedule/clinic-specific-schedule.component';
import { ClinicSettingsComponent } from './clinic-management/clinic-settings/clinic-settings.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    MenuSidebarComponent,
    SkeletonTableLoadingComponent,
    UploadFileComponent,
    NotificationsDropdownMenuComponent,
    MessagesDropdownMenuComponent,
    AutocompleteComponent,
    MedicalDocumentsUploadComponent,
    FileUploadComponent,
    CredentialsComponent,
    DidntReceiveCodeModalComponent,
    ClinicDetailComponent,
    ClinicManageScheduleComponent,
    ClinicManagementComponent,
    ClinicBlocksScheduleComponent,
    ClinicRegularAvailabilityComponent,
    ClinicTimeBlockGeneratorComponent,
    ClinicSpecificScheduleComponent,
    ClinicSettingsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteModule,
    SkeletonModule,
    FileUploadModule,
    DialogModule,
    PasswordModule,
    AccordionModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    MenuSidebarComponent,
    SkeletonTableLoadingComponent,
    UploadFileComponent,
    NotificationsDropdownMenuComponent,
    MessagesDropdownMenuComponent,
    AutocompleteComponent,
    MedicalDocumentsUploadComponent,
    FileUploadComponent,
    CredentialsComponent,
    DidntReceiveCodeModalComponent,
    ClinicDetailComponent,
    ClinicManageScheduleComponent,
    ClinicManagementComponent,
    ClinicBlocksScheduleComponent,
  ]
})
export class SharedModule { }
