<nav class="main-header navbar navbar-expand navbar-light bg-primary" [class.navbar-white]="!darkMode" [class.navbar-dark]="darkMode">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()"
      pTooltip="Expand/Collapse" tooltipPosition="right" tooltipZIndex="99999"
        ><i class="text-white fas fa-bars"></i
      ></a>
    </li>
  </ul>
  <ul class="navbar-nav pl-2">
    <li class="nav-item">
      <h4 class="mb-0">mWell MD Admin Portal</h4>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item text-center">
      <small>Today is</small><br>
      <b>{{ currentDate | date:'MMMM d, y' }}</b>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item d-flex align-items-center">      
      <small>Welcome, {{ user?.full }}</small>
        <div class="dropdown">
          <img 
              [src]="user?.doctor?.profile_photo_url"
              onerror="this.src='./assets/img/patient_profile.png';"
            class="profile-pic-btn ml-2 cursor-pointer" 
            data-toggle="dropdown">
          <ul class="dropdown-menu dropdown-menu-right pt-1 pb-1 pl-1">
            <li><a class="nav-link cursor-pointer font-weight-bold" (click)="logout()">Logout</a></li>
          </ul>
        </div>   
    </li>
    <app-notifications-dropdown-menu *ngIf="+user?.is_new_user === 0"></app-notifications-dropdown-menu>    
  </ul>
</nav>
