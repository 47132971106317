import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { api_url } from 'src/app/utils/helper/url';
import { ApiService } from 'src/app/utils/services/api.service';

@Component({
  selector: 'app-upload-file',
  styleUrls: ['./upload.component.css'],
  templateUrl: './upload.component.html',
})
export class UploadFileComponent implements OnInit {
  @ViewChild('filuploader')
  filuploader: FileUpload;

  @Input()
  isDisabled: any = false;

  @Output()
  upload = new EventEmitter<any>();

  @Output()
  remove = new EventEmitter<any>();

  @Output()
  uploadingImage = new EventEmitter<any>();

  @ViewChild('fileInput')
  fileInput: ElementRef;

  @Input()
  folder: string;

  @Input()
  disk: string = 'public';

  @Input()
  accept = 'image/*';

  @Input()
  maxFileSize = 2000000;

  @Input()
  name = 'file';

  @Input()
  disableFields: boolean = false;

  @Input()
  url: any;

  @Input()
  mode = 'advanced';

  removedFiles: any[] = [];

  uploadedFiles: any[] = [];

  uploading: boolean = false;

  constructor(
    private service: ApiService
  ) {
    this.url = this.service.getUploadUrl();
  }

  ngOnInit(): void {
  }

  onComplete(event) {
    this.uploading = false;

    event?.originalEvent?.body.forEach((file) => {
      this.uploadedFiles.push(file);
    });

    return this.upload.emit(event?.originalEvent?.body);
  }

  onBeforeSend(event) {
    event.formData.append('folder', this.folder);
    event.formData.append('disk', this.disk);
  }

  removeImage(file) {
    const index = this.uploadedFiles.indexOf(file);
    this.uploadedFiles.splice(index, 1);
    this.remove.emit(file);
  }

  onSelect() {
    this.uploadingImage.emit();
  }

  onSend() {
    this.uploading = true;
  }

  isPDF(url: string) {
    return url
      ? url.substr(url?.length - 3, url?.length).toLowerCase() === 'pdf'
        ? true
        : false
      : false;
  }

  reset() {
    this.uploadedFiles = [];
    this.uploading = false;
  }

  attachUrl(filename) {
    return `${api_url}/storage/${filename}`;
  }
}
