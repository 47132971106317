export const week = [
    {
        name: 'Mon',
        display: 'Monday',
        code: 'M'
    },
    {
        name: 'Tue',
        display: 'Tuesday',
        code: 'T'
    },
    {
        name: 'Wed',
        display: 'Wednesday',
        code: 'W'
    },
    {
        name: 'Thur',
        display: 'Thursday',
        code: 'TH'
    },
    {
        name: 'Fri',
        display: 'Friday',
        code: 'F'
    },
    {
        name: 'Sat',
        display: 'Saturday',
        code: 'S'
    },
    {
        name: 'Sun',
        display: 'Sunday',
        code: 'SU'
    },
];