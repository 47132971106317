<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <h3 class="text-muted">Unavailable on Specific Dates and Time</h3>
                    <p class="text-muted">Use this to BLOCK OFF <strong class="text-danger">specific dates and times</strong> from regular schedules, e.g. a morning family event, an afternoon meeting, a whole day personal errand. Appointments cannot be set on these dates and times.</p>
                </div>
                <div class="card-body">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <th>Enter Specific Date</th>
                            <th>Time Range</th>
                        </thead>
                        <tbody [formGroup]="form">
                            <tr *ngFor="let exception of exceptions?.controls; let i = index" formArrayName="exceptions">
                                <td [formGroup]="exception">
                                    <input [class.required-border]="exception?.get('exception_date')?.errors?.required" type="date" class="form-control" formControlName="exception_date" (change)="updateDates(exception)" style="margin-top: 31px;" >
                                </td>
                                <td [formGroup]="exception">
                                    <div class="pr-3 pl-3">
                                        <div class="form-row mb-3" >
                                            <div class="col">
                                                <label>Start time<b class="text-red ml-2" *ngIf="exception?.get('effective_start_time')?.errors?.required">*</b></label>
                                                <input type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="effective_start_time">
                                            </div>
                                            <div class="col">
                                                <label>End Time<b class="text-red ml-2" *ngIf="exception?.get('effective_end_time')?.errors?.required">*</b></label>
                                                <input type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="effective_end_time">
                                            </div>
                                            <div class="col">
                                                <label>Display Text<b class="text-red ml-2" *ngIf="exception?.get('exception_display')?.errors?.required">*</b></label>
                                                <input type="text" placeholder="Text" class="form-control" formControlName="exception_display">
                                            </div>
                                            <div class="col">
                                                <label>Exception Note<b class="text-red ml-2" *ngIf="exception?.get('exception_note')?.errors?.required">*</b></label>
                                                <input type="text" placeholder="Notes" class="form-control" formControlName="exception_note">
                                            </div>
                                            <div class="col-auto">
                                                <button class="btn btn-danger" style="margin-top: 31px;" (click)="removeException(i)">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input cursor-pointer" type="checkbox" id="is_repeating" value="1" formControlName="is_repeating">
                                                        <label for="is_repeating" class="custom-control-label cursor-pointer">Is Repeating</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="exceptions?.value?.length < 1">
                                <td colspan="2">No Schedule</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt-3">
                        <button class="btn btn-primary" (click)="addException()">
                            <i class="fas fa-plus mr-2"></i>Add Exception
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>