export const environment = {
  production: true,
  cas_url: 'https://emr.mwellmd.com.ph/cas-api',
  cas_ver: '/api/v1',
  doctor_url: 'https://emr.mwellmd.com.ph/doctor-api',
  doctor_ver: '/api/v1',
  schedule_url: 'https://emr.mwellmd.com.ph/schedule-api',
  schedule_ver: '/api/v1',
  mpi_url: 'https://emr.mwellmd.com.ph/mpi-api',
  mpi_ver: '/api/v1',
  utilities_url: 'https://emr.mwellmd.com.ph/utilities-api',
  utilities_ver: '/api/v1',
  
  // pusher info,
  pusher_app_cluster: 'ap1',
  pusher_app_id: '1410527',
  pusher_app_key: 'b6bd23aa92eb2e767cc0',
  pusher_app_secret: 'b5b8d70cd9845185deb4'
};
  