import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '', redirectTo: 'doctor-list'
      },
      {
        path: 'patient-worklist',
        loadChildren: () => import('./views/patient-worklist/patient-worklist.module').then( m => m.PatientWorklistModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then( m => m.ProfileModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then( m => m.DashboardModule)
      },
      {
        path: 'doctor-list',
        loadChildren: () => import('./views/doctor-list/doctor-list.module').then( m => m.DoctorListModule)
      },
      {
        path: 'edit-doctor-profile',
        loadChildren: () => import('./views/edit-doctor-profile/edit-doctor-profile.module').then( m => m.EditDoctorProfileModule)
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./views/subscriptions/subscriptions.module').then( m => m.SubscriptionsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then( m => m.ReportsModule)
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: '', redirectTo: 'login'
  // },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
