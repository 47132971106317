import { Component, OnInit, ViewChild } from '@angular/core';
import { getMasterByName } from 'src/app/utils/helper/utilities';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { AutocompleteComponent } from 'src/app/components/autocomplete/autocomplete.component';
import { SpecialtyMastersService } from 'src/app/utils/services/doctor/specialty-masters.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { HospitalMastersService } from 'src/app/utils/services/doctor/hospital-masters.service';
import { HmoMastersService } from 'src/app/utils/services/doctor/hmo-masters.service';

@Component({
  selector: 'app-edit-doctor-profile',
  templateUrl: './edit-doctor-profile.component.html',
  styleUrls: ['./edit-doctor-profile.component.scss']
})
export class EditDoctorProfileComponent implements OnInit {
  @ViewChild('specialtyComp', {static: false})
  specialtyComp: AutocompleteComponent;
  specialties: any = [];

  @ViewChild('hospitalComp', {static: false})
  hospitalComp: AutocompleteComponent;
  hospitalMasters: any = [];

  @ViewChild('hmoComp', {static: false})
  hmoComp: AutocompleteComponent;
  hmoMasters: any = [];

  languageSpoken: any = null;
  user: any = null;
  selectedLanguages: any = [];
  form: FormGroup;  
  
  constructor(
    public specialtyMastersService: SpecialtyMastersService,
    public hospitalMastersService: HospitalMastersService,
    public hmoMastersService: HmoMastersService,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER,true);
    this.languageSpoken = getMasterByName('Languages');
    console.log(this.languageSpoken);
    this.selectedLanguages = this.user?.languages_spoken?.split(',');
  }

  searchHMOMasters(term) {
    this.hmoMastersService.getHMOMasters({ query: term })
    .subscribe((response: any) => {
      this.hmoMasters = response?.data
    });
  }

  searchHospitalMasters(term) {
    this.hospitalMastersService.getHospitalMasters({ query: term })
    .subscribe((response: any) => {
      this.hospitalMasters = response?.data;
    });
  }

  updateHmoForm() {
    this.hmoAccreditationsArray.clear();
    this.hmoComp.selectedResult.forEach((item) => {
      this.hmoAccreditationsArray.push(this.createHmo(item));
    });
  }

  updateHospitalForm() {
    this.hospitalAffiliationsArray.clear();
    this.hospitalComp.selectedResult.forEach((item) => {
      this.hospitalAffiliationsArray.push(this.createAffiliation(item));
    });
  }

  searchSpecialties(term) {
    this.specialtyMastersService.getSpecialtyMasters({ query: term, specialty: true })
    .subscribe((response: any) => {
      this.specialties = response?.data;
    });
  }

  updateSpecialtyForm() {
    this.specialtiesArray.clear();
    this.specialtyComp.selectedResult.forEach((item) => {
      this.specialtiesArray.push(this.createSpecialties(item));
    });
  }

  get hmoAccreditationsArray() {
    return this.form.get('hmo_accreditations') as FormArray;
  }

  get hospitalAffiliationsArray() {
    return this.form.get('hospital_affiliations') as FormArray;
  }

  get specialtiesArray() {
    return this.form.get('specialties') as FormArray;
  }

  createHmo(item) {
    return this.fb.group({
      hmo_code: [item?.hmo_code],
      hmo_text: [item?.hmo_text],
      hmo_display: [item?.hmo_display],
    });
  }

  createAffiliation(item) {
    return this.fb.group({
      health_facility_code_short: [item?.health_facility_code_short]
    });
  }

  createSpecialties(specialty) {
    return this.fb.group({
      type_code: ['SP'],
      type_text: ['Specialty'],
      type_display: ['Specialty'],
      specialty_code: [specialty?.code],
      specialty_text: [specialty?.text],
      specialty_display: [specialty?.display],
      parent_specialty_code: [specialty?.parent_specialty?.code],
      parent_specialty_text: [specialty?.parent_specialty?.text],
      parent_specialty_display: [specialty?.parent_specialty?.display],
    });
  }

}
