import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/utils/services/api.service';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { UtilitiesService } from 'src/app/utils/services/utilities/utilities.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {  
  @ViewChild('contentWrapper', { static: false }) contentWrapper;

  public sidebarMenuOpened = true;
  user: any;

  constructor(
    private renderer: Renderer2,
    private service: ApiService,
    private cookieService: CookieService,
    private utilService: UtilitiesService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.user = getStorage(CURRENT_USER,true);
    this.init();
    this.getUtilities();
  }

  init() {
    if(this.cookieService.get('settings.dark-mode')) {
      this.document.body.classList.add('dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
    }
  }

  getUtilities() {
    const includes: any[] = [
      '101', // Marital Status
      '103', // Nationality
      '104', // religion
      '158', // languages
    ];

    const queryParams: any = {};
    queryParams.perPage = 'all';
    queryParams.includes = 'details';
    queryParams.codes = includes.join(',');

    this.utilService.getValueMaster(queryParams)
    .subscribe((response: any) => {
      localStorage.setItem('value_master', JSON.stringify(response?.data));
    },
    (err) => {});
  }

  mainSidebarHeight(height) {
    // this.renderer.setStyle(
    //   this.contentWrapper.nativeElement,
    //   'min-height',
    //   height - 114 + 'px'
    // );
  }

  toggleMenuSidebar() {
    console.log('sidebarMenuCollapsed', this.sidebarMenuOpened);
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
      this.renderer.addClass(document.body, 'sidebar-open');
      this.sidebarMenuOpened = true;
    }
  }
}
