import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_url, appendQueryParams } from '../helper/url';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  authenticate(data, queryParams = {}) {
    return this.http.post(
        `${api_url}/authenticate/admin${appendQueryParams(queryParams)}`, data
    );
  }

  verify(data, queryParams = {}) {
    return this.http.post(
        `${api_url}/verify${appendQueryParams(queryParams)}`, data
    );
  }

  logout(queryParams = {}) {
    return this.http.delete(
        `${api_url}/logout${appendQueryParams(queryParams)}`
    );
  }
  
  resetPassword(id, data = {}, queryParams = {}) {
    return this.http.put(
        `${api_url}/user/${id}/reset-password${appendQueryParams(queryParams)}`, data
    );
  }
  
  changePassword(id, data = {}, queryParams = {}) {
    return this.http.put(
        `${api_url}/user/${id}/change-password${appendQueryParams(queryParams)}`, data
    );
  }

  me(queryParams = {}) {
    return this.http.get(
        `${api_url}/me${appendQueryParams(queryParams)}`
    );
  }

  getDocuments(queryParams = {}) {
    return this.http.get(
        `${api_url}/documents${appendQueryParams(queryParams)}`
    );
  }

  getDocument(id, queryParams = {}) {
    return this.http.get(
        `${api_url}/documents/${id}${appendQueryParams(queryParams)}`
    );
  }

  getDocumentOwners(queryParams = {}) {
    return this.http.get(
        `${api_url}/document-owners${appendQueryParams(queryParams)}`
    );
  }

  getDocumentOwner(id, queryParams = {}) {
    return this.http.get(
        `${api_url}/document-owners/${id}${appendQueryParams(queryParams)}`
    );
  }
  
  getSelection(queryParams = {}) {
    return this.http.get(
        `${api_url}/select${appendQueryParams(queryParams)}`
    );
  }
  
  saveDocument(id, data, queryParams = {}) {
    return this.http.post(
        `${api_url}/document-owners/${id}/document${appendQueryParams(queryParams)}`, data
    );
  }
  
  deleteDocument(id, queryParams = {}) {
    return this.http.delete(
        `${api_url}/documents/${id}${appendQueryParams(queryParams)}`
    );
  }
  
  removePhoto(queryParams = {}) {
    return this.http.delete(
        `${api_url}/uploads/remove${appendQueryParams(queryParams)}`
    );
  }

  getUploadUrl() {
    return `${api_url}/uploads/multiple`;
  }

  saveDocumentOwner(data, queryParams = {}) {
    return this.http.post(
        `${api_url}/document-owners${appendQueryParams(queryParams)}`, data
    );
  }

  updateDocumentOwner(id, data, queryParams = {}) {
    return this.http.put(
        `${api_url}/document-owners/${id}${appendQueryParams(queryParams)}`, data
    );
  }

  deleteDocumentOwner(id, queryParams = {}) {
    return this.http.delete(
        `${api_url}/document-owners/${id}${appendQueryParams(queryParams)}`
    );
  }
  
  pdf(id, queryParams = {}) {
    return this.http.get(
        `${api_url}/documents/${id}/pdf${appendQueryParams(queryParams)}`,
        {
          headers: {
            'Content-Type': 'application/octet-stream,application/json',
          },
          responseType: 'blob',
        }
    );
  }
}
