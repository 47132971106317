<div class="p-4">
    <h6><b>Doctors <i class="fas fa-chevron-right px-2"></i> <strong> Doctor's Profile</strong></b></h6>
    <div class="card light-shadow border-none">
        <div class="card-body">
            <div class="row p-4 mx-4">
                <div class="col-5">
                    <span>Profile Picture</span>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <span>PRC ID</span>
                </div>

                <div class="col-5 py-2">
                    <label for="last" class="font-weight-normal">Last Name</label>
                    <input type="text" id="last" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <label for="prc-number" class="font-weight-normal">PRC Number</label>
                    <input type="text" id="prc-number" class="form-control form-control-sm"/>
                </div>

                <div class="col-5 py-2">
                    <label for="first" class="font-weight-normal">First Name</label>
                    <input type="text" id="first" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <label for="registered-date" class="font-weight-normal">Registered Date</label>
                    <input type="date" id="registered-date" class="form-control form-control-sm"/>
                </div>
                
                <div class="col-5 py-2">
                    <label for="middle" class="font-weight-normal">Middle Name</label>
                    <input type="text" id="middle" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <label for="valid-until" class="font-weight-normal">Valid Until</label>
                    <input type="date" id="valid-until" class="form-control form-control-sm"/>
                </div>

                <div class="col-5 py-2">
                    <label for="suffix" class="font-weight-normal">Suffix</label>
                    <input type="text" id="suffix" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2 mt-4">
                    <label class="switch pt-2">
                        <input type="checkbox">
                        <span class="slider round"></span>
                    </label>
                    <span>
                        PRC is Verified
                    </span>
                </div>

                <div class="col-5 py-2">
                    <label for="title-suffix" class="font-weight-normal">Title Suffix</label>
                    <input type="text" id="title-suffix" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    Languages Spoken
                    <p-multiSelect [options]="languageSpoken?.details" [(ngModel)]="selectedLanguages" [ngModelOptions]="{ standalone: true }" defaultLabel="Select Languages Spoken" optionLabel="text" optionValue="uid" display="chip">
                    </p-multiSelect>
                </div>

                <div class="col-5 py-2">
                    Gender
                    <div class="d-flex flex-row pt-2">
                        <label class="container">Male
                            <input type="radio" checked="checked" name="gender">
                            <span class="checkmark"></span>
                        </label>
                        <label class="container">Female
                            <input type="radio" name="gender">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    Specialty
                    <app-autocomplete #specialtyComp (search)="searchSpecialties($event)" 
                        [results]="specialties" [field]="'text'" (select)="updateSpecialtyForm()" 
                        (unselect)="updateSpecialtyForm()"></app-autocomplete>
                </div>

                <div class="col-5 py-2">
                    <label for="dob" class="font-weight-normal">Date of Birth</label>
                    <input type="date" id="dob" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    Hospital Affiations
                    <app-autocomplete #hospitalComp (search)="searchHospitalMasters($event)"
                        [results]="hospitalMasters" [field]="'facility_name'"
                        (select)="updateHospitalForm()" (unselect)="updateHospitalForm()"></app-autocomplete>
                </div>

                <div class="col-5 py-2">
                    <label for="email" class="font-weight-normal">Email Address</label>
                    <div class="d-flex flex-row">
                        <input type="text" id="email" class="form-control form-control-sm mr-1"/>
                        <button type="button" class="btn btn-outline-secondary" title="copy">
                            <i class="fas fa-copy"></i>
                        </button>
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    HMO Accrediations
                    <app-autocomplete #hmoComp (search)="searchHMOMasters($event)"
                        [results]="hmoMasters" [field]="'hmo_text'"
                        (select)="updateHmoForm()" (unselect)="updateHmoForm()"></app-autocomplete>
                </div>

                <div class="col-5 py-2">
                    <label for="contact" class="font-weight-normal">Contact Number</label>
                    <input type="text" id="contact" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <label for="price" class="font-weight-normal">Price Per Teleconsult</label>
                    <input type="text" id="price" class="form-control form-control-sm"/>
                </div>

                <div class="col-5 py-2">
                    <label for="s2-license" class="font-weight-normal">S2 License Number</label>
                    <input type="text" id="s2-license" class="form-control form-control-sm"/>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <label for="ptr" class="font-weight-normal">PTR Number</label>
                    <input type="text" id="ptr" class="form-control form-control-sm"/>
                </div>

                <div class="col-5 py-2">
                    S2 License Upload
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    PTR Number Upload
                </div>

                <div class="col-5 py-2">
                    <strong>Documents Upload</strong>
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                    <strong>Medical Document</strong>
                </div>

                <div class="col-5 py-2">
                    E-Signature
                </div>
                <div class="col-2"></div>
                <div class="col-5 py-2">
                </div>

                <div class="col-12 py-2">
                    Tagging/Sorting
                </div>
                <div class="col-12 pt-2">
                    <div class="card">
                        <div class="card-body d-flex flex-row py-3">
                            <label class="container-tag mb-0 mr-4">mWell Plus
                                <input type="checkbox" checked="checked" name="tag">
                                <span class="checkmark-tag"></span>
                            </label>
                            <label class="container-tag mb-0 mx-4">MPHHI Doctor
                                <input type="checkbox" name="tag">
                                <span class="checkmark-tag"></span>
                            </label>
                            <label class="container-tag mb-0 mx-4">Featured
                                <input type="checkbox" name="tag">
                                <span class="checkmark-tag"></span>
                            </label>
                            <label class="container-tag mb-0 ml-4">Pinned
                                <input type="checkbox" name="tag">
                                <span class="checkmark-tag"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12 py-2">
                    Account Status : <strong>Active</strong>
                </div>
                <div class="col-7 py-2">
                    <div class="card">
                        <div class="card-body d-flex flex-row py-3">
                            <label class="container mb-0">Pending
                                <input type="radio" checked="checked" name="status">
                                <span class="checkmark"></span>
                            </label>
                            <label class="container mb-0">Active
                                <input type="radio" name="status">
                                <span class="checkmark"></span>
                            </label>
                            <label class="container mb-0">Deactivate
                                <input type="radio" name="status">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-5"></div>

                <div class="col-12 py-2">
                    Groups
                    <select class="custom-select">
                        <option selected>Open this select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                </div>

                <div class="col-12 pt-4">
                    <button
                        style="min-width: 200px"
                        type="button"
                        class="btn btn-primary btn-flat btn-lg float-right ml-2"
                        >
                        Save
                    </button>
                    <button
                        style="min-width: 200px"
                        type="button"
                        class="btn btn-outline-dark btn-flat btn-lg float-right"
                        >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>