import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PERMANENT } from 'src/app/utils/items/address-type';
import { PHYSICAL_CLINIC } from 'src/app/utils/items/clinic-type';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { ClinicDetailComponent } from '../clinic-detail/clinic-detail.component';
import { ClinicManageScheduleComponent } from '../clinic-manage-schedule/clinic-manage-schedule.component';

@Component({
  selector: 'app-clinic-management',
  templateUrl: './clinic-management.component.html',
  styleUrls: ['./clinic-management.component.scss']
})
export class ClinicManagementComponent implements OnInit {

  @ViewChild('clinicInfo', {static: false})
  clinicInfo: ClinicDetailComponent;

  @ViewChild('manageSchedule', {static: false})
  manageSchedule: ClinicManageScheduleComponent;

  @Input()
  doctor: any;

  @Input()
  clinics: any;

  @Input()
  processing = false;

  @Input()
  serviceItems: any;

  @Input()
  onboarding = false;

  form: FormGroup;
  selectedClinic: any = null;
  

  constructor(
    private fb: FormBuilder,
    private service: DoctorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.clinics) {
      this.selectedClinic = this.clinics?.[0];
      this.onClinicChange();
    }
  }

  createForm() {
    this.form = this.fb.group({
      clinic: this.fb.group({
        id: [this.selectedClinic?.id],
        md_id: [this.doctor?.md_id],
        type_code: [PHYSICAL_CLINIC?.code],
        type_display: [PHYSICAL_CLINIC?.text],
        type_text: [PHYSICAL_CLINIC?.text],
        name_code: [null],
        name_display: [this.selectedClinic?.name_display, [Validators.required]],
        name_text: [this.selectedClinic?.name_text],
        site_name_code: [this.selectedClinic?.site_name_code],
        site_name_text: [this.selectedClinic?.site_name_text],
        site_name_display: [this.selectedClinic?.site_name_display],
        site_network_code: [this.selectedClinic?.site_network_code],
        site_network_text: [this.selectedClinic?.site_network_text],
        site_network_display: [this.selectedClinic?.site_network_display],
        notes: [this.selectedClinic?.notes],
        attachment: [this.selectedClinic?.attachment]
      }),
      address: this.fb.group({
        id: [this.selectedClinic?.permanent_address?.id],
        type_code: [PERMANENT?.code],
        type_text: [PERMANENT?.text],
        type_display: [PERMANENT?.text],
        line1: [this.selectedClinic?.permanent_address?.line1],
        line2: [this.selectedClinic?.permanent_address?.line2],
        barangay_code: [this.selectedClinic?.permanent_address?.barangay_code],
        barangay_text: [this.selectedClinic?.permanent_address?.barangay_text],
        city_code: [this.selectedClinic?.permanent_address?.city_code],
        city_text: [this.selectedClinic?.permanent_address?.city_text],
        state_code: [this.selectedClinic?.permanent_address?.state_code],
        state_text: [this.selectedClinic?.permanent_address?.state_text],
        country_code: [this.selectedClinic?.permanent_address?.country_code],
        country_text: [this.selectedClinic?.permanent_address?.country_text],
        zip_code: [this.selectedClinic?.permanent_address?.zip_code],
        zip_code_text: [this.selectedClinic?.permanent_address?.zip_code_text],
      }),
      contact: this.fb.group({
        id: [this.selectedClinic?.landline?.id],
        type_code: [this.selectedClinic?.landline?.type_code || 'L'],
        type_text: [this.selectedClinic?.landline?.type_text || 'Landline'],
        type_display: [this.selectedClinic?.landline?.type_display || 'Landline'],
        prefix: [this.selectedClinic?.landline?.prefix],
        value: [this.selectedClinic?.landline?.value],
        provider: [this.selectedClinic?.landline?.provider],
        remarks: [this.selectedClinic?.landline?.remarks],
      }),
      services: this.fb.array([]),
      exceptions: this.fb.array([]),
    });
  }

  setClinic(clinic) {
    this.selectedClinic = clinic;

    setTimeout(() => {
      this.onClinicChange();
    }, 1);
  }

  setClinicDetail(clinic) {
    this.selectedClinic = clinic;
    
    setTimeout(() => {
      this.onClinicChange();
    }, 1);
  }

  onClinicChange() {
    this.createForm();
    setTimeout(() => {
      if(this.selectedClinic?.permanent_address?.state_code) {
        this.clinicInfo.listMunicipalities();
      }
    }, 1);
  }

  updateClinic(value) {

    this.setClinic(value);

    const index = this.clinics?.findIndex((clinic) => +clinic?.id === +value?.id);

    if(index > -1) {
      this.clinics[index] = value;
    }
  }

  delete(id) {

    const index =  this.clinics?.findIndex((clinic) => +clinic?.id === +id);

    if(index > -1) {
      this.clinics.splice(index, 1);
    }

    this.setClinic(this.clinics?.[0]);
  }
}
