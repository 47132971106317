<div class="form-group">
  <p-fileUpload
    name="{{ name }}"
    accept="{{ accept }}"
    url="{{ url }}"
    maxFileSize="10000000"
    multiple="multiple"
    auto="auto"
    (onBeforeUpload)="onBeforeSend($event)"
    (onUpload)="onComplete($event)"
    (onSelect)="onSelect()"
    (onSend)="onSend()"
    *ngIf="!disableFields"
    #filuploader
  >
    <ng-template pTemplate="content">
      <div class="uploader-display" *ngIf="uploadedFiles">
          <div *ngIf="uploadedFiles.length < 1" class="flex-center d-flex justify-content-center align-items-center">
              <i class="far fa-images mr-2 text-muted" style="font-size: 1.5rem;"></i>
              <span class="gray">Click Browse to upload files.</span>
          </div>
          <div *ngFor="let file of uploadedFiles" class="file-view font-open-sans" style="position: relative;">
            <div class="file-item" [style.backgroundImage]="'url(' + file?.file_url + ')'" >
            </div>
            <div title="{{ file.name }}"style="margin-left: 5px; width: 100px; overflow: hidden;" class="pull-left">{{ file.name }}</div>
            <i class="fa fa-times-circle text-danger pull-right remove-file" title="Remove Image" (click)="removeImage(file)"></i>
          </div>
      </div>
    </ng-template>  
  </p-fileUpload>
</div>
