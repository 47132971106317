<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- <a [routerLink]="['/dashboard']" class="brand-link d-flex justify-content-center"> -->
    <!-- <span class="brand-text font-weight-light bold">Mwell MD</span> -->
  <!-- </a> -->
  <div class="sidebar mt-0 os-scrollbar">
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="assets/img/logo.png" alt="User Image" />
      </div>
      <!-- <div class="info">
        <a [routerLink]="['/profile']" class="d-block">Alexander Pierce</a>
      </div> -->
    </div>
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li class="nav-item mt-2 hidden">
          <a [routerLink]="['dashboard']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('dashboard')">
            <i class="nav-icon fas fa-book-medical mr-2"></i>
            <p style="white-space: nowrap">
              Dashboard
            </p>
          </a>
        </li>
        <li class="nav-item mt-2">
          <a [routerLink]="['doctor-list']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('doctor-list') || (isCurrentPath('profile') && (isCurrentPath('information') || isCurrentPath('credentials') || isCurrentPath('security')))">
            <i class="nav-icon fas fa-user-md mr-2"></i>
            <p style="white-space: nowrap">
              Doctors
            </p>
          </a>
        </li>
        <li class="nav-item mt-2">
          <a [routerLink]="['/reports']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('reports')">
            <i class="nav-icon fas fa-chart-bar mr-2"></i>
            <p style="white-space: nowrap">
              Reports
            </p>
          </a>
        </li>
        <li class="nav-item mt-2">
          <a [routerLink]="['subscriptions']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('subscriptions')">
            <i class="nav-icon fas fa-clipboard-list mr-2"></i>
            <p style="white-space: nowrap">
              Subscriptions
            </p>
          </a>
        </li>
        <li class="nav-item mt-2 hidden">
          <a [routerLink]="['patient-worklist']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('patient-worklist')">
            <i class="nav-icon fas fa-clipboard-list mr-2"></i>
            <p style="white-space: nowrap">
              Consults
            </p>
          </a>
        </li>
        <li class="nav-item mt-2 hidden">
          <a [routerLink]="['profile']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('reports')">
            <i class="nav-icon fas fa-book mr-2"></i>
            <p style="white-space: nowrap">
              Reports
            </p>
          </a>
        </li>
        <li class="nav-item mt-2 hidden">
          <a [routerLink]="['profile']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('attendance')">
            <i class="nav-icon fas fa-id-badge mr-2"></i>
            <p style="white-space: nowrap">
              Attendance
            </p>
          </a>
        </li>
        <li class="nav-item mt-2">
          <a class="nav-link cursor-pointer" (click)="logout()" [attr.disabled]="processing">
            <i *ngIf="!processing" class="nav-icon fas fa-sign-out-alt mr-2"></i>
            <i *ngIf="processing" class="nav-icon fas fa-circle-notch spinning"></i>
            <p style="white-space: nowrap">
              Log out
            </p>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</aside>
