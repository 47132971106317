<img class="logo" src="assets/img/logo.png" width="150px">

<!-- /.login-logo -->
<div class="card light-shadow">
  <div class="card-body login-card-body text-center">
    <div class="login-form">
      <h2 class="text-primary bold">Welcome to mWell MD</h2>
      <h5>Please login to get started</h5>

      <form [formGroup]="loginForm">
        <fieldset [disabled]="processing" [@listAnimation]="3">
          <div class="input-group mb-4 mt-4" *ngIf="!processing">
            <input
              formControlName="email"
              type="email"
              class="form-control"
              placeholder="Email"
              autocomplete="new-password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3" *ngIf="!processing">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              placeholder="Password"
              autocomplete="new-password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="processing">
            <div class="col">
              <i class="ml-2 fas fa-circle-notch spinning"></i>
            </div>
          </div>
          <div class="row mt-3" *ngIf="!processing">
            <div class="col">
              <button
                (click)="logIn()"
                type="submit"
                class="btn btn-primary btn-block btn-flat btn-lg"
                [disabled]="loginForm?.invalid"
              >
                Login
                <i *ngIf="processing" class="ml-2 fas fa-circle-notch spinning"></i>
              </button>
              <!-- <button class="btn btn-danger btn-block btn-flat btn-lg" (click)="loginWithGoogle()">
                <i class="fab fa-google mr-2"></i> Sign in with Google
              </button> -->
            </div>
            <!-- /.col -->
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <a class="text-warning cursor-pointer">Forgot Password?</a>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  <!-- /.login-card-body -->
</div>