<p-dialog
  [baseZIndex]="2000"
  [style]="{ minWidth: '600px', marginTop: '30px'  }"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
    <fieldset [disabled]="processing">
        <div class="pt-3 pr-3 pl-3 form-row">
            <div class="col-auto">
                <label for="selectedIntervalMon" style="font-weight: 500; margin-bottom: 0px;">Bulk Schedule Intervals</label><br>
                <select class="form-control form-control-lg" [(ngModel)]="interval">
                    <option *ngFor="let option of optionsTimeInterval" [value]="option?.value">{{ option?.label }}</option>
                </select>
                <!-- <p-dropdown [options]="optionsTimeInterval" formControlName="interval" optionLabel="label" optionValue="value" (onChange)="display($event)">
                </p-dropdown> -->
            </div>
            <div class="col-auto">
                <label for="selectedDayStart" style="font-weight: 500; margin-bottom: 0px;">Day Start Time</label><br>
                <input type="time" class="form-control form-control-lg" [(ngModel)]="start">
            </div>
            <div class="col-auto">
                <label for="selectedDayStart" style="font-weight: 500; margin-bottom: 0px;">Day End Time</label><br>
                <input type="time" class="form-control form-control-lg" [(ngModel)]="end">
            </div>
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
        <button role="button" class="btn btn-primary btn-lg mr-2" (click)="generateTimeBlock()" [disabled]="processing || (!start && !start && !interval)">
            Generate
            <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
            Close
        </button>
        </div>
    </p-footer>
</p-dialog>
