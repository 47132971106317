import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendQueryParams, doctor_url ,schedule_url } from '../../helper/url';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  getSchedule(queryParams = {}) {
    return this.http.get(
      `${schedule_url}/schedules${appendQueryParams(queryParams)}`
    );
  }

  getAppointmentDashboard() {
    return this.http.get(
      `${schedule_url}/appointments/dashboard`
    );
  }
  
  getAppointmentGraph(queryParams = {}) {
    return this.http.get(
      `${schedule_url}/appointments/graph/${appendQueryParams(queryParams)}`
    );
  }
  
  saveClinicSchedule(clinicID, data = {}, queryParams = {}) {
    return this.http.put(
      `${schedule_url}/clinic/${clinicID}/schedules${appendQueryParams(queryParams)}`, data
    );
  }

  deleteClinic(clinicID, queryParams = {}) {
    return this.http.delete(
      `${doctor_url}/clinics/${clinicID}${appendQueryParams(queryParams)}`
    );
  }
  
  getServiceItems(queryParams = {}) {
    return this.http.get(
        `${schedule_url}/service-items${appendQueryParams(queryParams)}`
    );
  }
}
