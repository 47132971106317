import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from '../../utils/services/app.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../utils/services/api.service';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { CasService } from 'src/app/utils/services/cas.service';
import { Subscription } from 'rxjs';
import { SocialService } from 'src/app/utils/services/social.service';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { Router } from '@angular/router';
import { TOKEN } from 'src/app/utils/items/storage-names';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({ transform: 'translateX(120px)', opacity: 0 }),
          stagger(300, [
            animate('0.3s', style({ 
              transform: 'translateX(0px)',
              opacity: 1
            }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  authSubscription: Subscription;
  loginForm: FormGroup;
  processing = false;
  user: any;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private service: ApiService,
    private doctorService: DoctorService,
    private socialAuthService: SocialAuthService,
    private socialService: SocialService,
    private casService: CasService,
    private fb: FormBuilder,    
  ) {}

  ngOnInit() {
    this.authSubscription = this.socialAuthService.authState.subscribe((user) => {
      console.log(user);
    });

    this.renderer.addClass(document.body, 'login-container');
    
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      remember_me: false
    });
  }

  async logIn() {
    this.processing = true;

    this.service.authenticate(this.loginForm.value)
    .subscribe((response: any) => {
      this.user = response?.user;
      localStorage.setItem(TOKEN, response?.access_token);
      this.appService.storeUser(this.user);
      this.router.navigate(['/doctor-list']);
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.processing = false;
    })
  }

  loginWithGoogle(): void {
   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
    this.socialService.login(user);
   });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
    this.authSubscription.unsubscribe();
  } 
}
