<div class="p-4">
    <h4><b>Doctors</b></h4> <br>
    <div class="d-flex justify-content-between mb-2">
        <div class="d-flex hidden">
            <button type="button" class="btn btn-light border btn-filter-first" (click)="updateFilterButton('')">All ({{
                doctorCount?.all }})</button>
            <button type="button" class="btn btn-light border btn-filter-inner"
                (click)="updateFilterButton('active')">Active ({{ doctorCount?.active }})</button>
            <button type="button" class="btn btn-light border btn-filter-inner"
                (click)="updateFilterButton('pending')">Pending ({{ doctorCount?.pending }})</button>
            <button type="button" class="btn btn-light border btn-filter-last"
                (click)="updateFilterButton('deactivated')">Deactivated ({{ doctorCount?.deactivated }})</button>
        </div>
        <div class="d-flex w-100">
            <div class="col-md-3">
                <span for="doctor" class="text-primary bold-500">Search by Doctor Name</span>
                <span class="p-input-icon-right w-100">
                    <i class="pi pi-search"></i>
                    <input id="doctor" type="text" pInputText class="form-control"
                        (keyup.enter)="searchInput($event.target.value)">
                </span>
            </div>
            <div class="col-md-2">
                <span for="status" class="text-primary bold-500">Account Status</span>
                <select id="status" class="form-control" name="mWellPlus"
                    (change)="updateDropdownValue('userStatus',$event.target.value)">
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="pending">Pending</option>
                    <option value="deactivated">Disapproved</option>
                </select>
            </div>
            <div class="col-md-7">
                <span for="specialty" class="text-primary bold-500">Specialty</span>
                <select id="specialty" class="form-control" name="mWellPlus"
                    (change)="updateDropdownValue('specialtyText',$event.target.value)">
                    <option value="">All</option>
                    <option *ngFor="let specialty of specialtyOptions?.data" [value]="specialty?.text">{{
                        specialty?.text }}</option>
                </select>
            </div>
            <div class="mr-2 hidden">
                <select class="form-control" name="mWellPlus"
                    (change)="updateDropdownValue('mwellPlusStatus',$event.target.value)">
                    <option value="">mWell Plus Status</option>
                    <option value="1">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div class="hidden">
                <select class="form-control" name="MPHHIStatus"
                    (change)="updateDropdownValue('mphhiStatus',$event.target.value)">
                    <option value="">MPHHI Status</option>
                    <option value="1">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row pt-3 pb-3">
        <div class="col">
            <button type="button" class="btn btn-primary" (click)="onExport()"
                [disabled]="!(doctors?.data?.length > 0) || loadingCSV">
                <i *ngIf="loadingCSV" class="fas fa-circle-notch spinning px-2"></i> Download CSV
            </button>
        </div>
        <div class="col">
            <div class="document-header align-items-center d-flex justify-content-end">
                <div class="d-flex align-items-center">Show:
                    <select class="form-control ml-3"
                        style="margin-right: -20px; width: 80px; height: 30px; padding-top: 3px" [(ngModel)]="perPage"
                        (change)="filterDoctorList()">
                        <option [ngValue]="per" *ngFor="let per of perPages">
                            {{ per }}
                        </option>
                    </select>
                    <pagination-controls (pageChange)="filterDoctorList($event)" id="paginator_controls">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid pt-3">
        <div class="row table-responsive" style="box-shadow: 5px 4px 33px rgba(3, 79, 152, 0.15);">
            <table class="table table-striped mb-0" style="table-layout: fixed">
                <thead>
                    <th class="bg-primary cursor-pointer align-middle" style="width: 280px"
                        (click)="sort('names.first')">Doctor Name<i class="fas fa-sort pl-2"></i></th>
                    <th class="bg-primary cursor-pointer align-middle" style="width: 170px"
                        (click)="sort('specialties.specialty_text')">Specialty<i class="fas fa-sort pl-2"></i></th>
                    <!-- <th class="bg-primary cursor-pointer align-middle" style="width: 170px"
                        (click)="sort('user.subscription')">Subscription<i class="fas fa-sort pl-2"></i></th> -->
                    <th class="bg-primary cursor-pointer align-middle" style="width: 130px"
                        (click)="sort('prc_license.number')">PRC #<i class="fas fa-sort pl-2"></i></th>
                    <th class="bg-primary cursor-pointer align-middle" style="width: 162px"
                        (click)="sort('prc_license.validated_at')">Activation Date<i class="fas fa-sort pl-2"></i></th>
                    <!--                    <th class="bg-primary cursor-pointer text-center align-middle hidden" style="width: 170px;" (click)="sort('user.is_mwell_plus')">mWell Plus<i class="fas fa-sort pl-2"></i></th>-->
                    <!--                    <th class="bg-primary cursor-pointer text-center align-middle hidden" style="width: 145px" (click)="sort('user.is_mphhi')">MPHHI<i class="fas fa-sort pl-2"></i></th>-->
                    <th class="bg-primary cursor-pointer text-center align-middle" style="width: 125px"
                        (click)="sort('user.account_status')">Status<i class="fas fa-sort pl-2"></i></th>
                    <th class="bg-primary cursor-pointer align-middle" style="width: 150px"
                        (click)="sort('user.logged_in_at')">Last Log-In<i class="fas fa-sort pl-2"></i></th>
                    <th class="bg-primary cursor-pointer text-center align-middle" style="width: 145px">Actions</th>
                </thead>

                <tbody skeleton-loader [rows]="5" [columns]="7" *ngIf="processing"></tbody>

                <tbody *ngIf="!processing">
                    <tr *ngFor="let doctor of doctors?.data | paginate : {
                        id: 'paginator_controls',
                        itemsPerPage: doctors?.per_page,
                        currentPage: doctors?.current_page,
                        totalItems: doctors?.total
                    };
                    ">
                        <td class="align-top">{{ doctor?.primary_name?.full }}</td>
                        <td class="align-top">
                            <span *ngFor="let specialty of doctor?.specialties">
                                {{ specialty?.specialty_text }}
                                <span
                                    *ngIf="doctor?.specialties?.length > 1 || doctor?.subspecialties?.length != 0"><br></span>
                            </span>
                            <span *ngFor="let subspecialty of doctor?.subspecialties">
                                {{ subspecialty?.specialty_text }}
                                <span *ngIf="doctor?.subspecialties?.length > 1"><br></span>
                            </span>
                        </td>
                        <!-- <td class="align-top text-break">
                            {{ doctor?.user?.active_subscription?.plan?.name }}
                        </td> -->
                        <td class="align-top text-break">
                            {{ doctor?.prc_license?.number }}
                        </td>
                        <td class="align-top">
                            {{ doctor?.prc_license?.validated_at | date: 'longDate' }}
                        </td>
                        <td class="align-top text-center hidden">{{ doctor?.user?.is_mwell_plus ? 'Yes': '-' }}</td>
                        <td class="align-top text-center hidden">{{ doctor?.user?.is_mphhi ? 'Yes': '-' }}</td>
                        <td>
                            <div class="text-center" [class.text-active]="doctor?.user?.account_status === 'active'"
                                [class.text-pending]="doctor?.user?.account_status === 'pending'"
                                [class.text-disapproved]="doctor?.user?.account_status === 'deactivated'">
                                {{ doctor?.user?.account_status == 'deactivated' ? "Disapproved" :
                                doctor?.user?.account_status | titlecase }}
                            </div>
                        </td>
                        <td class="align-top">
                            {{ doctor?.user?.logged_in_at | date: 'longDate' }}
                        </td>
                        <td class="align-top">
                            <a class="btn btn-status btn-info text-center"
                                [routerLink]="['/profile', doctor?.md_id, 'information']">Edit</a>
                        </td>
                    </tr>

                    <tr *ngIf="doctors?.data?.length < 1">
                        <td colspan="8" style="background-color: #fff;">No Doctor Found</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="row pt-3 pb-3">
            <div class="col">
                <div class="document-header align-items-center d-flex justify-content-end">
                    <div class="d-flex align-items-center">Show:
                        <select class="form-control ml-3"
                            style="margin-right: -20px; width: 80px; height: 30px; padding-top: 3px"
                            [(ngModel)]="perPage" (change)="filterDoctorList()">
                            <option [ngValue]="per" *ngFor="let per of perPages">
                                {{ per }}
                            </option>
                        </select>
                        <pagination-controls (pageChange)="filterDoctorList($event)" id="paginator_controls">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
