import { Component, OnInit } from '@angular/core';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { SpecialtyMastersService } from 'src/app/utils/services/doctor/specialty-masters.service copy';
import { AngularCsv } from 'angular-csv-ext';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { getMasterByName } from 'src/app/utils/helper/utilities';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class DoctorListComponent implements OnInit {

  perPages = [5, 10, 25, 50, 100];
  processing = false;
  page = 1;
  perPage = 25;
  doctors: any;
  activeSort = 'asc';
  activeColumn = 'created_at';
  filterButton = '';
  userStatus = '';
  specialtyText = '';
  searchText = '';
  doctorCount: any;
  specialtyOptions: any;
  loadingCSV: boolean = false;

  constructor(
    private doctorService: DoctorService,
    private specialties: SpecialtyMastersService,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.doctorService.getDoctorsCount().subscribe((response) => {
      this.doctorCount = response;
    });

    this.listSpecialties();
    this.filterDoctorList();
  }

  listSpecialties() {
    const queryParams: any = {};
    queryParams.order = 'text';

    this.specialties.getSpecialtyMasters(queryParams).subscribe((res: any) => this.specialtyOptions = res);
  }

  filterDoctorList(page = 1) {
    this.doctors = null;
    this.processing = true;
    let queryParams: any = {};
    queryParams.includes = 'user,names,specialties,prc_license';
    queryParams.perPage = this.perPage;
    queryParams.page = this.page = page;
    queryParams.user_status = this.userStatus;
    queryParams.specialty_text = this.specialtyText;
    queryParams.query = this.searchText;
    queryParams.order = this.activeColumn;
    queryParams.sort = this.activeSort;

    this.getDoctors(queryParams).subscribe((response: any) => {
      this.processing = false;
      this.doctors = response;
    });
  }

  getDoctors(queryParams: any): Observable<any> {
    return this.doctorService.getDoctors(queryParams)
  }

  updateFilterButton(value) {
    this.filterButton = value;
    this.filterDoctorList();
  }

  updateDropdownValue(column, value) {
    this[column] = value;
    this.filterDoctorList();
  }

  searchInput(value) {
    this.searchText = value;
    this.filterDoctorList();
  }

  sort(column) {
    if (column === this.activeColumn) {
      this.activeSort = this.activeSort === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeSort = 'asc';
    }

    this.activeColumn = column;

    this.filterDoctorList();
  }

  updateFilterDropdownStatus(value) {
    this.userStatus = value;
    this.filterDoctorList();
  }

  updateFilterDropdownSpecialty(value) {
    this.specialtyText = value;
    this.filterDoctorList();
  }

  onExport() {
    let queryParams: any = {};

    queryParams.includes = 'user,names,specialties,prc_license,clinics,hmo,ptr_license,s2_license';
    queryParams.perPage = this.perPage;
    queryParams.page = 'all';
    queryParams.user_status = this.userStatus;
    queryParams.specialty_text = this.specialtyText;
    queryParams.query = this.searchText;
    queryParams.order = this.activeColumn;
    queryParams.sort = this.activeSort;
    this.loadingCSV = true;

    this.getDoctors(queryParams).subscribe((response: any) => {
      this.loadingCSV = false;

      if (Array.isArray(response) && response?.length > 0) {
        let items = response.map((item) => ({
          'Doctor Name': item?.primary_name?.full,
          // 'Subscription': item?.user?.active_subscription?.plan?.name,
          'Activation Date': item?.prc_license?.validated_at ? this.datePipe.transform(item?.prc_license?.validated_at, 'longDate') : '',
          'Status': this.accountStatus(item?.user?.account_status),
          'Last Log-in': item?.user?.logged_in_at ? this.datePipe.transform(item?.user?.logged_in_at, 'longDate') : '',
          'Prefix/Title': item?.primary_name?.prefix,
          'First Name': item?.primary_name?.first,
          'Middle Name': item?.primary_name?.middle,
          'Last Name': item?.primary_name?.last,
          'Suffix': item?.primary_name?.suffix,
          'Title Suffix Degrees': item?.primary_name?.degrees,
          'Sex': item?.primary_name?.degrees,
          'Date of Birth': item?.date_of_birth,
          'Email Address': item?.user?.email,
          'Mobile Number': item?.user?.contact_no,
          'Languages': getMasterByName('Languages')?.details?.filter(lang => item?.user?.languages_spoken?.split(',').indexOf(lang.uid) > -1 && lang.is_active === 1).map(lang => lang.text).join('; '),
          'Specialty': this.doctorSpecialty(item?.specialties ?? [], []),
          'Subspecialty': this.doctorSpecialty([], item?.subspecialties ?? []),
          'Hospitals/Clinics': item?.clinics.map(clinic => clinic.name_display).join('; '),
          'HMO Accreditations': item?.hmo.map(hmo => hmo.hmo_display).join('; '),
          'PRC Number': item?.prc_license?.number,
          'PRC Registered Date': item?.prc_license?.validity_start_datetime,
          'PRC Expiration Date': item?.prc_license?.validity_expiry_datetime,
          'PTR Number': item?.ptr_license?.number,
          'S2': item?.s2_license?.number,
        }));

        let headers = Object.keys(items[0]);

        new AngularCsv(items, 'doctors_' + (new Date()).getTime(), {
          headers,
          nullToEmptyString: true
        });
      }
    }, err => {
      console.warn('onExport doctors: ', err);
      this.loadingCSV = false;
    });
  }

  accountStatus(accountStatus: string): string {
    if (accountStatus === 'deactivated') {
      return 'Disapproved';
    }

    return this.titleCasePipe.transform(accountStatus);
  }

  doctorSpecialty(specialties: any, subspecialty: any): string {
    let sp = '';
    let subsp = '';

    if (specialties != null || specialties.length > 0) {
      specialties.forEach((s, idx) => {
        if (specialties.length - 1 === idx) {
          sp += s?.specialty_text;
        } else {
          // sp += s?.specialty_text + '\n';
          sp += s?.specialty_text + '; ';
        }
      });
    }

    if (subspecialty != null || subspecialty.length > 0) {
      subspecialty.forEach((s, idx) => {
        if (subspecialty.length - 1 === idx) {
          subsp += s?.specialty_text;
        } else {
          // subsp += s?.specialty_text + '\n';
          subsp += s?.specialty_text + '; ';
        }
      });
    }

    return sp + subsp;
  }
}
