import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-clinic-time-block-generator',
  templateUrl: './clinic-time-block-generator.component.html',
  styleUrls: ['./clinic-time-block-generator.component.scss']
})
export class ClinicTimeBlockGeneratorComponent implements OnInit {

  @Output()
  generate: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  schedule: FormGroup;

  optionsTimeInterval: any = [
    {label: 'Every 10 minutes', value: 0.167},
    {label: 'Every 15 minutes', value: .25},
    {label: 'Every 30 minutes', value: .5},
    {label: 'Every 1 Hour', value: 1},
    {label: 'Every 2 hours', value: 2},
  ];

  modal = false;
  processing = false;

  interval = 1;
  start: any;
  end: any;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
  }

  onOpen(schedule) {
    this.schedule = schedule;
    this.processing = false;
    this.modal = true;
    this.interval = 1;
    this.start = null;
    this.end = null;
  }

  onClose() {
    this.modal = false;
  }

  generateTimeBlock() {
    const moment = extendMoment(_moment);

    let slots = this.schedule.get('slots') as FormArray;

    const startDatestring = moment().utc().format("YYYY-MM-DD") + " " + this.start + ':00';
    const endtDatestring = moment().utc().format("YYYY-MM-DD") + " " + this.end + ':00';

    let start = moment(new Date(startDatestring));
    let end = moment(new Date(endtDatestring));
    let datesBetween = [];

    while(start.isBefore(end,'minutes')) {
      // start = moment(start).add(schedule?.value?.interval,'hour');
      const startSlot = start.clone();
      
      start.add(this.interval,'hour');

      const endSlot = start.clone();

      slots.push(this.fb.group({
        id: [],
        slot_type: ['generic'],
        slot_start_time: [startSlot.format("HH:mm"), Validators.required],
        slot_end_time: [endSlot.format("HH:mm"), Validators.required],
        slot_duration: [this.interval],
        slot_notes: [],
        number_of_bookings: [1],
        allow_overbooking: [0],
        override_generic: [0],
      }));
    }

    this.onClose();
  }
}
