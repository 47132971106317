import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clinic-settings',
  templateUrl: './clinic-settings.component.html',
  styleUrls: ['./clinic-settings.component.scss']
})
export class ClinicSettingsComponent implements OnInit {

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  clinic: any;

  constructor() { }

  ngOnInit(): void {
  }

  deleteClinic() {
    if(confirm("Are you sure you want to delete this clinic?")) {
      this.delete.emit(this.clinic?.id);
    }
  }

}
